<template>
  <div class="sjlogin">
    <div class="login_pc">
      <div class="login_pcbox">
        <div class="login_pccenter">
          <div class="login_pccenterlogo">
            <router-link to="/"
              ><img
                src="https://task.hozoin.cn/Works/20210119/sj-1758342099.png"
                alt=""
            /></router-link>
            <router-link class="login_pccenterlogoqx" to="/">取消</router-link>
          </div>
          <div class="login_pccenterbox">
            <div class="login_pccenterboxtitle" style="height: 2rem">
              <div class="login_pccenterboxtitleimg">
                <img
                  v-if="wxtype"
                  src="https://task.hozoin.cn/Works/20210201/sj-3828233331.png"
                  alt=""
                  @click="logintype(1)"
                />
                <img
                  v-if="pctype"
                  src="https://task.hozoin.cn/Works/20210201/sj-9120328028.png"
                  alt=""
                  @click="logintype(2)"
                />
              </div>
            </div>
            <el-tabs v-if="pcboxtype" v-model="first" @tab-click="changedl">
              <el-tab-pane label="密码登录" name="first">
                <div class="login_pccenterboxmm">
                  <input
                    type="text"
                    @input="iptchange"
                    v-model="textt"
                    id="login_username"
                    placeholder="请输入手机号/邮箱"
                  />
                  <input
                    type="password"
                    @input="iptchange"
                    v-model="passwordt"
                    name=""
                    id="login_password"
                    placeholder="请输入账号密码"
                  />
                  <div class="login_pccenterboxmmck">
                    <el-checkbox v-model="checked">24小时内自动登录</el-checkbox>
                  </div>
                  <div
                    class="login_pccenterboxmmbtn"
                    :style="login_pccenterboxmmbtnbk"
                    @click="login()"
                  >
                    登录
                  </div>
                  <div class="login_pccenterboxmmzc">
                    <div @click="Retrieve()">忘记密码</div>
                    <div></div>
                    <div><router-link to="/Sjzhuce">立即注册</router-link></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="验证码登录" name="two">
                <div class="login_pccenterboxmm">
                  <input
                    type="text"
                    id="ponint"
                    v-model="ponint"
                    @input="iptponma"
                    placeholder="请输入手机号"
                  />
                  <div class="login_pccenterboxmmpro">
                    <input
                      type="text"
                      v-model="ponma"
                      @input="iptpon"
                      id="ponma"
                      placeholder="请输入验证码"
                    />
                    <input
                      class="login_pccenterboxmmprohq reg_get_code"
                      :class="login_pccenterboxmmprohq"
                      style="top: 2.7rem"
                      readonly="readonly"
                      @click="getponcode"
                      :value="shejishim"
                      :style="login_pccenterboxmmprohqbk"
                    />
                  </div>
                  <div class="login_pccenterboxmmck">
                    <el-checkbox v-model="checked">24小时内自动登录</el-checkbox>
                  </div>
                  <div
                    class="login_pccenterboxmmbtn"
                    :style="login_pccenterboxmmbtnbk"
                    @click="loginpon()"
                  >
                    登录
                  </div>
                  <div class="login_pccenterboxmmzc">
                    <div><router-link to="/Sjzhuce">立即注册</router-link></div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="wxbox" v-if="wxboxtype">
              <div class="wxbox_title" @click="wechat">微信登陆</div>
              <img :src="wechatimg" class="weixin_2wm" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axiossj from "../assets/js/axiosfz";
export default {
  data() {
    return {
      wxtype: true,
      wxboxtype: false,
      pctype: false,
      pcboxtype: true,
      first: "first",
      checked: "false",
      textt: "",
      passwordt: "",
      ponint: "",
      ponma: "",
      content: "",
      mimashow: true,
      phoneshow: false,
      dlfs: "短信",
      login_pccenterboxmmbtnbk: "background:#e4e4e4",
      login_pccenterboxmmprohqbk: "background:#e4e4e4",
      shejishim: "获取验证码",
      login_pccenterboxmmprohq: "",
      wechatimg: "",
      token: "",
      innum: 0,
      timer: null,
      timerwc: null,
      count: 0,
      token: "",
    };
  },
  mounted() {
    // 绑定enter事件
    this.enterKeyup();
  },
  destroyed() {
    // 销毁enter事件
    this.enterKeyupDestroyed();
  },
  methods: {
    changedl(index) {
      let that = this;
      that.textt = "";
      that.passwordt = "";
      that.ponint = "";
      that.ponma = "";
      that.login_pccenterboxmmbtnbk = "background:#e4e4e4";
      that.login_pccenterboxmmprohqbk = "background:#e4e4e4";
    },
    iptchange() {
      var that = this;
      if (that.textt != "" && that.passwordt != "") {
        that.login_pccenterboxmmbtnbk = "background:#fc9732";
      } else {
        that.login_pccenterboxmmbtnbk = "background:#e4e4e4";
      }
    },
    Retrieve() {
      //忘记密码
      this.first = "two";
    },
    login() {
      let that = this;
      this.tokenaxios();
    },
    iptponma() {
      let that = this;
      if (that.ponint != "") {
        that.login_pccenterboxmmprohqbk = "background:#fc9732";
        if (that.ponma != "") {
          that.login_pccenterboxmmbtnbk = "background:#fc9732";
        }
      } else {
        that.login_pccenterboxmmbtnbk = "background:#e4e4e4";
        that.login_pccenterboxmmprohqbk = "background:#e4e4e4";
      }
    },
    iptpon() {
      var that = this;
      if (that.ponma != "" && that.ponint != "") {
        that.login_pccenterboxmmbtnbk = "background:#fc9732";
      } else {
        that.login_pccenterboxmmbtnbk = "background:#e4e4e4";
      }
    },
    getponcode(id) {
      let that = this;
      //   获取验证码
      this.$axios({
        url: "/api/package/getMobileCode",
        method: "post",
        data: {
          mobile: that.ponint,
          get_type: "getLandCode",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: "验证码发送成功，请注意查收",
            duration:3000,
          });
          const TIME_COUNT = 60;
          if (that.timer == null) {
            that.login_pccenterboxmmprohq = "clicknone";
            that.count = TIME_COUNT;
            that.login_pccenterboxmmprohqbk = "background:#e4e4e4";
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= TIME_COUNT) {
                that.count--;
                that.shejishim = "" + that.count + "秒后重新获取";
              } else {
                that.login_pccenterboxmmprohqbk = "#fc9732";
                clearInterval(that.timer);
                that.timer = null;
                that.shejishim = "获取验证码";
              }
            }, 1000);
          }
        } else {
          this.$message({
            message: "验证码发送失败，请刷新页面重新发送！",
            duration: 3000,
          });
        }
      });
    },
    loginpon() {
      // 手机号登录
      var that = this;
      this.$axios({
        url: "/api/settings/textLogin",
        method: "post",
        data: {
          username: that.ponint,
          code: that.ponma,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          console.log(res);
          this.$message({
            message: res.data.messages,
            duration: 1000,
          });

          var checkedIdStr = JSON.stringify(res.data.result);
          localStorage.setItem("userinfo", checkedIdStr);
          localStorage.setItem("tokentime", Date.now());
          setTimeout(function () {
            that.$router.go(-1);
          }, 1000);
        } else {
          this.$message({
            message: "验证码发送失败，请刷新页面重新发送！",
            duration: 3000,
          });
        }
      });
    },
    denglu() {
      var that = this;
      this.$axios({
        url: "/api/settings/tokenVerify",
        method: "post",
        data: {
          token: that.token,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: res.data.messages,
            duration: 3000,
          });

          var checkedIdStr = JSON.stringify(res.data.result);
          if (res.data.result.mobile == null || res.data.result.mobile == "") {
            this.$router.push({
              path: "/Sjzhuce",
              query: {
                data: res.data.result.userid,
                name: res.data.result.username,
                userinfo: checkedIdStr,
              },
            });
          } else {
            localStorage.setItem("userinfo", checkedIdStr);
            localStorage.setItem("tokentime", Date.now());
            setTimeout(function () {
              that.$router.go(-1);
            }, 1000);
          }
        } else {
          that.innum = that.innum + 1;
          setTimeout(function () {
            if (that.innum < 60) {
              that.denglu();
            } else {
              that.innum = 0;
            }
          }, 1000);
        }
      });
    },
    wechat() {
      var that = this;
      this.$axios({
        url: "/api/settings/wechat",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.code == 1) {
          that.wechatimg = res.data.result.url;
          ///////////
          that.token = res.data.result.token;
          that.denglu();
          ///////////
        } else {
        }
      });
    },
    logintype(type) {
      console.log(type);
      if (type == 1) {
        this.wxtype = false;
        this.wxboxtype = true;
        this.pcboxtype = false;
        this.pctype = true;
        this.wechat();
      } else {
        this.innum = 60;
        this.wxtype = true;
        this.wxboxtype = false;
        this.pcboxtype = true;
        this.pctype = false;
      }
    },
    tokenaxios() {
      var that = this;
      var reg = /^[\da-z]+$/i;
      if (reg.test(that.passwordt)) {
        var b = that.passwordt.split("");
        if (b.length < 6) {
          this.$message({
            message: "密码最少为6位！",
            duration: 3000,
          });
          return false;
        }
      } else {
        this.$message({
          message: "密码只能输入字母和数字！",
          duration: 3000,
        });
        return false;
      };
      let passwordt = this.$md5(that.passwordt);
      this.$axios({
        url: "/api/settings/login",
        method: "post",
        data: {
          username: that.textt,
          password: passwordt,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          console.log(res);
          this.$message({
            message: res.data.messages,
            duration: 1000,
          });

          var checkedIdStr = JSON.stringify(res.data.result);
          localStorage.setItem("userinfo", checkedIdStr);
          localStorage.setItem("tokentime", Date.now());
          setTimeout(function () {
            that.$router.go(-1);
          }, 1000);
        } else {
          this.$message({
            message: res.data.messages,
            duration: 1000,
          });
        }
      });
    },
    enterKey(event) {
      if (event.keyCode == 13) {
        if (this.first == "first") {
          this.login();
        } else {
          this.loginpon();
        }
      }
    },
    enterKeyupDestroyed() {
      document.removeEventListener("keyup", this.enterKey);
    },
    enterKeyup() {
      document.addEventListener("keyup", this.enterKey);
    },
  },
  created() {
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;
    if (userinfo) {
      this.$router.push({ path: "/" });
    }
  },
};
</script>
<style>
@import "../assets/css/login.css";
</style>